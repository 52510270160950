(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-selector-2023/assets/javascripts/draw-selector-link-item.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-selector-2023/assets/javascripts/draw-selector-link-item.js');

'use strict';

const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const {
  analytics
} = svs.components;
const {
  composeHref,
  composeTitle
} = svs.components.tipsen.drawCard.utils;
const {
  If
} = svs.components.lbUtils.reactConditionals;
const DrawSelectorLinkItem = _ref => {
  let {
    children,
    isLinkItem,
    onToggleDrawSelector,
    drawNumber,
    productId,
    drawComment,
    description,
    betIndex,
    regCloseTime
  } = _ref;
  const createDrawSearchParam = () => {
    const newHref = composeHref(drawNumber, productId);
    return newHref.replace('?', '');
  };
  const title = composeTitle({
    drawComment,
    description,
    productId,
    betIndex,
    regCloseTime
  });
  const onClick = () => {
    analytics.trackEvent({
      category: "Nya tipsen",
      action: 'change_draw',
      opt_label: "".concat(title, "-").concat(drawNumber)
    });
    onToggleDrawSelector();
  };
  return React.createElement(React.Fragment, null, React.createElement(If, {
    condition: isLinkItem
  }, React.createElement(TipsenLink, {
    onClick: onClick,
    tabIndex: "-1",
    to: {
      pathname: '',
      search: "?".concat(createDrawSearchParam())
    }
  }, children)), React.createElement(If, {
    condition: !isLinkItem
  }, children));
};
setGlobal('svs.components.tipsen.drawSelector.DrawSelectorLinkItem', DrawSelectorLinkItem);

 })(window);