(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-selector-2023/assets/javascripts/draw-selector-header.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-selector-2023/assets/javascripts/draw-selector-header.js');
"use strict";


const {
  useHistory
} = ReactRouterDOM;
const {
  Form,
  ControlGroup,
  Select,
  Option
} = svs.ui.reactForm;
const {
  DRAW_CARD_TITLES
} = svs.components.tipsen.drawCard.constants;
const {
  getIconBySportType
} = svs.components.tipsen.drawCard.utils;
const {
  DRAW_SORT_FLOW,
  DRAW_SORT_QS,
  DRAW_FILTER_QS
} = svs.components.tipsen.drawSelector.constants;
const {
  ReactIcon: Icon,
  ReactButton: Button,
  ReactIconButton: IconButton
} = svs.ui;
const {
  SportTypeToString
} = svs.components.sport.sportTypes;
const {
  productIds
} = svs.utils.products;
const getDefaultSortFlow = productId => {
  switch (productId) {
    case productIds.MATCHEN:
      return DRAW_SORT_FLOW.NET_SALE.value;
    default:
      return DRAW_SORT_FLOW.REG_CLOSE_TIME.value;
  }
};
const DrawSelectorHeader = _ref => {
  let {
    hasExtraMoneyValue,
    onToggleFunc,
    blockClassName,
    sportTypes = [],
    shouldDisableTitle = false,
    productId
  } = _ref;
  const history = useHistory();
  const qs = new URLSearchParams(history.location.search);

  sportTypes.sort();
  const handleOnSelect = event => {
    const sortFlow = event.target.value;
    if (qs.get(DRAW_SORT_QS) === sortFlow || Boolean(!hasExtraMoneyValue && sortFlow === DRAW_SORT_FLOW.EXTRA_MONEY.value)) {
      return;
    }
    qs.set(DRAW_SORT_QS, sortFlow);
    history.replace("".concat(history.location.pathname, "?").concat(qs.toString()));
  };
  const handleFilterClick = sportTypeValue => {
    const filterValues = qs.getAll(DRAW_FILTER_QS);
    const sport = SportTypeToString[sportTypeValue];
    const isSportTypeFiltered = filterValues.includes(sport);
    if (isSportTypeFiltered) {
      filterValues.splice(filterValues.indexOf(sport), 1);
    } else {
      filterValues.push(sport);
    }
    qs.delete(DRAW_FILTER_QS);
    if (filterValues.length) {
      filterValues.forEach(value => {
        qs.append(DRAW_FILTER_QS, value);
      });
    }
    history.replace("".concat(history.location.pathname, "?").concat(qs.toString()));
  };
  const defaultSortFlow = getDefaultSortFlow(productId);
  const selectedSortFlow = qs.get(DRAW_SORT_QS) || defaultSortFlow;
  const shouldRenderSportTypesFilter = sportTypes.length > 1;
  const filteredValues = qs.getAll(DRAW_FILTER_QS);
  const dropDownSortValues = Object.values(DRAW_SORT_FLOW);
  return React.createElement("div", {
    className: "pg_draw_selector__header",
    role: "rowgroup"
  }, React.createElement("div", {
    className: "".concat(blockClassName, "__headers"),
    role: "row"
  }, !shouldDisableTitle && React.createElement("div", {
    className: "".concat(blockClassName, "__headers_title")
  }, "V\xE4lj spelomg\xE5ng"), React.createElement("div", {
    className: "".concat(blockClassName, "__sort_container")
  }, React.createElement(Form, null, React.createElement(ControlGroup, {
    v2: true
  }, React.createElement(Select, {
    onChange: handleOnSelect,
    value: selectedSortFlow
  }, dropDownSortValues.map(sortFlow => React.createElement(Option, {
    "aria-label": sortFlow.title,
    disabled: Boolean(sortFlow.title === DRAW_CARD_TITLES.EXTRA_MONEY && !hasExtraMoneyValue),
    key: sortFlow.value,
    name: sortFlow.value,
    value: sortFlow.value
  }, sortFlow.title)))))), shouldRenderSportTypesFilter && React.createElement("div", {
    className: "".concat(blockClassName, "__filter_container")
  }, sportTypes.map(sportType => {
    const isChecked = filteredValues.includes(SportTypeToString[sportType]);
    return React.createElement(IconButton, {
      "aria-label": SportTypeToString[sportType],
      className: "".concat(blockClassName, "__filter_icon_button ").concat(isChecked ? "".concat(blockClassName, "__filter_icon_button_checked") : ''),
      key: sportType,
      onClick: () => handleFilterClick(sportType),
      size: 400
    }, React.createElement(Icon, {
      color: isChecked ? 'icon-inverted' : '',
      icon: getIconBySportType(sportType)
    }));
  })), onToggleFunc && React.createElement(Button, {
    className: "".concat(blockClassName, "__headers__button"),
    iconPosition: "center",
    onClick: onToggleFunc,
    size: 400,
    transparent: true
  }, React.createElement(Icon, {
    icon: "close"
  }))));
};
setGlobal('svs.components.tipsen.drawSelector.DrawSelectorHeader', DrawSelectorHeader);

 })(window);